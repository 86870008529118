import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import Drink from '../list/Drink';
import './TimeDiscountModalContentStyle.css';

function TimeDiscountModalContent(props) {
    const { onClickHandler } = props;

    const { restaurant } = useRestaurant();

    const { t } = useTranslation();

    let products = [];
    if (restaurant.beverages.length > 0 && restaurant.beverages[0].drinks.length > 0) {
        products.push(restaurant.beverages[0].drinks[0]);
    }

    return (
        <div className="time-discount-modal-content overflow-auto text-black ">
            <p className="text-center p-3">Gustati una bella bevanda</p>
            <div className="discount-products">
                {0 === products.length ?
                    (<p>No products.</p>) :
                    (products.map((drink, i) => <Drink drink={drink} key={i} isTastingMenu={false} />))}
            </div>
        </div>
    )
}

export default TimeDiscountModalContent;
