import axios from 'axios';

export const SUBSCRIPTION = "https://api-ristoratori.mycia.it/v2/restaurants/newsletter";

async function doPost(payload) {
    const fd = new FormData();
    fd.append("ris_id", payload.id);
    fd.append("name", payload.name);
    fd.append("surname", payload.surname);
    fd.append("email", payload.email);
    fd.append("phone", payload.phone);

    var response = await axios.post(SUBSCRIPTION, fd);

    return response && response.data;
}

export default {
    doPost,
}
