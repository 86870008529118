import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import ListBeverage from '../list/Beverage';
import MenuBeverage from '../menu/Beverage';
import Row from '../list/Row';
import Preferred from '../list/Preferred';
import TastingList from '../list/TastingList';
import { useCart } from '../../utils/contexts/cart-context';
import TastingMenu from '../menu/TastingMenu';
import Course from '../menu/Course';
import theme from '../../utils/theme';
import SearchDrink from './SearchDrink';
import DrinkOP from '../list/Drink';
import Drink from '../menu/Drink';

function NewTabs(props) {
    const { hasOrders } = props;
    const { restaurant } = useRestaurant();
    const { t } = useTranslation();
    const { add } = useCart();

    const [result, setResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    let def;
    if ("mycia" === process.env.REACT_APP_NAMESPACE || "test" === process.env.REACT_APP_NAMESPACE) {
        if (restaurant.tasting_menu.length == 0) {
            def = 0;
        } else {
            def = restaurant.tasting_menu.length;
        }
    } else {
        def = restaurant.tasting_menu.length + 1;
    }

    // if (restaurant.courses.length == 0) {
    //     def = restaurant.tasting_menu.length + 1;
    // }

    if ("campari" === process.env.REACT_APP_NAMESPACE ||
        "aperolspritz" === process.env.REACT_APP_NAMESPACE ||
        "averna" === process.env.REACT_APP_NAMESPACE ||
        "camparisoda" === process.env.REACT_APP_NAMESPACE ||
        "wild" === process.env.REACT_APP_NAMESPACE ||
        "espolon" === process.env.REACT_APP_NAMESPACE ||
        "bulldog" === process.env.REACT_APP_NAMESPACE ||
        "braulio" === process.env.REACT_APP_NAMESPACE ||
        "crodinoxl" === process.env.REACT_APP_NAMESPACE) {
        let tmpTabs = [];
        for (let tab of restaurant.tasting_menu) {
            tmpTabs.push(tab.name);
        }
        if (restaurant.courses.length) {
            tmpTabs.push("Piatti");
        }
        if (restaurant.beverages.length) {
            tmpTabs.push("LISTA VINI/BEVANDE");
        }
        def = (tmpTabs.indexOf("LISTA VINI/BEVANDE"));
    }

    const [activeTab, setIsActiveTab] = useState(def);

    function renderCustomTabName(idx) {
        if (idx == 0) {
            let customName = restaurant.tabs.length > 0 && restaurant.tabs[idx];

            if ("morgana" === process.env.REACT_APP_NAMESPACE) {
                customName = "CUCINA";
            } else if ("redcafe" === process.env.REACT_APP_NAMESPACE) {
                customName = "FOOD";
            } else if (!customName) {
                customName = t("tab.button_1");
            }

            return customName;
        } else if (idx == 1) {
            let customName = restaurant.tabs.length > 0 && restaurant.tabs[idx];

            if (!customName) {
                customName = theme.getCustomElement(restaurant.id, "newtabs", t);
            }

            return customName;
        }
    }

    function renderTab() {
        return <TabList>
            <div className="tabs relative">
                <div className="bar overflow-x-scroll flex mx-3 py-3">
                    {restaurant.tasting_menu.map((t, i) => (
                        <Tab key={i} index={i} label={t.name} translate={false}>
                            <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                                "rounded-full", "border", "border-solid", "border-mycia-blue",
                                "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab == i })}>{t.name}
                            </div>
                        </Tab>
                    ))}
                    {restaurant.courses.length > 0 && <Tab>
                        <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                            "rounded-full", "border", "border-solid", "border-mycia-blue",
                            "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab == restaurant.tasting_menu.length })}>
                            {renderCustomTabName(0)}
                        </div>
                    </Tab>}
                    <Tab>
                        <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                            "rounded-full", "border", "border-solid", "border-mycia-blue",
                            "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab == restaurant.tasting_menu.length + (restaurant.courses && restaurant.courses.length ? 1 : 0) })}>
                            {renderCustomTabName(1)}
                        </div>
                    </Tab>
                    <Tab>
                        <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                            "rounded-full", "border", "border-solid", "border-mycia-blue",
                            "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab == restaurant.tasting_menu.length + (restaurant.courses && restaurant.courses.length ? 2 : 1) })}>
                            {t("tab.button_3")}
                        </div>
                    </Tab>
                </div>
            </div>
        </TabList>
    }

    function renderTabCoursesContent() {
        if (hasOrders == true) {
            if (restaurant.courses && restaurant.courses.length > 0) {
                return <TabPanel>
                    {(restaurant.courses && restaurant.courses.length > 0) ?
                        restaurant.courses.map((course, i) => <Row course={course} index={i} key={i} />) : <p className="text-center">Nessun piatto disponibile</p>}
                </TabPanel>
            } else return null;
        } else {
            if (restaurant.courses && restaurant.courses.length > 0) {
                return <TabPanel>
                    {(restaurant.courses && restaurant.courses.length > 0) ?
                        restaurant.courses.map((course, i) => <Course course={course} index={i} key={i} />) : <p className="text-center">Nessun piatto disponibile</p>}
                </TabPanel>
            } else return null;
        }
    }

    return (
        <Tabs defaultIndex={def} onSelect={(i) => setIsActiveTab(i)}>

            {renderTab()}

            {restaurant.id == 11945 &&
                <div className="m-2">
                    <input className="px-4 py-2 w-full border rounded" onClick={() => alert("Feature non ancora utilizzabile")} placeholder="Cerca piatto" />
                </div>}

            {/* tabs content render here*/}
            {hasOrders == true ?
                restaurant.has_tasting_menu && restaurant.tasting_menu.map(tastingMenu => (
                    <TabPanel>
                        <p className="px-4 text-base">{tastingMenu.description}</p>
                        {tastingMenu.courses.map((course, i) => (
                            <TastingList course={course} index={i} key={i} tastingMenu={tastingMenu} />
                        ))}
                        <p className="text-center py-4 pt-6 font-semibold">Prezzo menù completo: {tastingMenu.price}€</p>
                        {tastingMenu.courses.length != 0 && <div className="cta-tasting-menu text-center py-2 px-4 m-4 bg-mycia-green rounded-full">
                            <button type="button" onClick={() => add(tastingMenu, 1)}>Ordina il menu</button>
                        </div>}
                    </TabPanel>
                ))
                :
                restaurant.has_tasting_menu && restaurant.tasting_menu.map(tastingMenu => (
                    <TabPanel>
                        <p className="px-4 text-base">{tastingMenu.description}</p>
                        {tastingMenu.courses.map((course, i) => (
                            <TastingMenu course={course} index={i} key={i} tastingMenu={tastingMenu} />
                        ))}
                        <p className="text-center py-4 font-semibold">Prezzo menù completo: {tastingMenu.price}€</p>
                    </TabPanel>
                ))
            }

            {/* {hasOrders == true ?
                <TabPanel>
                    {(restaurant.courses && restaurant.courses.length > 0) ?
                        restaurant.courses.map((course, i) => <Row course={course} index={i} key={i} />) : <p className="text-center">Nessun piatto disponibile</p>}
                </TabPanel> :
                <TabPanel>
                    {(restaurant.courses && restaurant.courses.length > 0) ?
                        restaurant.courses.map((course, i) => <Course course={course} index={i} key={i} />) : <p className="text-center">Nessun piatto disponibile</p>}
                </TabPanel>
            } */}
            {renderTabCoursesContent()}

            <div>
                {hasOrders == true ?
                    <TabPanel>
                        <div className='block w-full m-auto px-4'>
                            <SearchDrink className="w-full my-2 p-2 text-black border rounded-lg outline-none" drinks={restaurant.beverages} setResult={setResult} setIsSearching={setIsSearching} />
                        </div>

                        <div className="beverages">
                            {isSearching ?
                                result.map(r =>
                                    r.drinks.length > 0 &&
                                    <div>
                                        <p className="px-4 py-2 uppercase font-bold">{r.course.name}</p>
                                        {r.drinks.map(drink =>
                                            <DrinkOP drink={drink} />
                                        )}
                                    </div>) :
                                (restaurant.beverages && restaurant.beverages.length > 0) &&
                                restaurant.beverages.map((beverage, i) => <ListBeverage beverage={beverage} index={i} key={i} />)}
                        </div>
                    </TabPanel> :
                    <TabPanel>
                        <div className='block w-full m-auto px-4'>
                            <SearchDrink className="w-full my-2 p-2 text-black border rounded-lg outline-none" drinks={restaurant.beverages} setResult={setResult} setIsSearching={setIsSearching} />
                        </div>

                        <div className="beverages">
                            {isSearching ?
                                result.map(r =>
                                    r.drinks.length > 0 &&
                                    <div>
                                        <p className="px-4 py-2 uppercase font-bold">{r.course.name}</p>
                                        {r.drinks.map(drink =>
                                            <Drink drink={drink} />
                                        )}
                                    </div>) :
                                (restaurant.beverages && restaurant.beverages.length > 0) &&
                                restaurant.beverages.map((beverage, i) => <MenuBeverage beverage={beverage} index={i} key={i} />)}
                        </div>
                    </TabPanel>}
            </div>
            <TabPanel>
                <Preferred />
            </TabPanel>
            {/* -------------------- */}
        </Tabs>
    );
}

export default NewTabs;
