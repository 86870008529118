import axios from 'axios';

export const ALLERGIES = "https://api-ristoratori.mycia.it/v2/foodstyle/allergies/";
export const DIETS = "https://api-ristoratori.mycia.it/v2/foodstyle/diets/?diets/";
export const FOODS = "https://api-ristoratori.mycia.it/v2/foodstyle/foods/";

async function doGetAllergies() {
    var response = await axios.get(ALLERGIES);

    return response && response.data.response;
}

async function doGetDiets() {
    var response = await axios.get(DIETS);

    return response && response.data.response;
}

async function doGetFoods() {
    var response = await axios.get(FOODS);

    return response && response.data.response;
}

export default {
    doGetAllergies,
    doGetDiets,
    doGetFoods,
}
