import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import NewTabs from './UI/NewTabs';
import FilterModalTrigger from './modals/FilterModalTrigger';
import assets from '../utils/assets';
import features from '../utils/features';
import formats from '../utils/formats';
import sharer from '../utils/sharer';
import { people, shareOutline } from 'ionicons/icons';
import SpoilerButton from './UI/SpoilerButton';
import CallRestaurantModalTrigger from './modals/CallRestaurantModalTrigger';
import LanguageSelector from './LanguageSelector';
import SubscriptionModalTrigger from './modals/SubscriptionModalTrigger';

function Menu(props) {
    const { restaurant } = props;

    const [showSubscriptionModal, setShowSubscriptionModal] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const { type, id } = useParams();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        let storage = window.localStorage.getItem("has_subscription");
        let obj = [{ id: "" }];
        if (storage) {
            obj = JSON.parse(storage);
            console.log(obj);
        }
        if (obj.filter(a => a.id == id).length > 0) {
            setShowSubscriptionModal(false);
        }

    }, [])

    return (
        <React.Fragment>
            {
                "mycia" !== process.env.REACT_APP_NAMESPACE ?
                    <div className="restaurant-image w-full p-4">
                        <img src={assets.assetsLogoPath(restaurant.id)} alt="test" />
                    </div> :
                    <img src={restaurant.image_path} alt={t("menu.img_1")} className="w-full" />
            }

            <div className="menu relative -mt-4 py-2 rounded-t-lg bg-white">
                <header className="px-4 pb-4 border-b border-b-gray-200">
                    <div className="flex justify-between items-center">
                        <div className="pr-2">
                            <h1 className="font-medium text-3xl">{restaurant.name}</h1>
                        </div>
                        <div className="flex items-center">
                            {features.toggler().includeLanguageSelector() ? <LanguageSelector onMenu={true} /> :
                                <div>
                                    <button onClick={() => (sharer.shareContent(`${type}/q/${id}`, restaurant.name, t("menu.button_1")))} className="button-native share w-10 h-10 flex items-center justify-center rounded-full shadow-lg bg-mycia-red text-white">
                                        <IonIcon icon={shareOutline} size="medium" className="mb-1 text-xl"></IonIcon>
                                    </button>
                                </div>}
                            {restaurant.has_phonecall ? restaurant.phone_number && type != "r" &&
                                <div className="mx-2">
                                    <CallRestaurantModalTrigger restaurant={restaurant} />
                                </div>
                                :
                                null
                            }
                            {restaurant.has_newsletter == true ?
                                showSubscriptionModal ?
                                    <button className="button-native subscription w-10 h-10 flex items-center justify-center rounded-full shadow-lg bg-mycia-red text-white" onClick={() => setShowModal(true)}>
                                        <IonIcon icon={people} />
                                    </button>
                                    :
                                    null
                                :
                                null
                            }
                        </div>
                    </div>

                    <SpoilerButton content={restaurant.menu_description} />
                    {formats.shouldDisplayRestaurantCoverCharge(restaurant, type) ?
                        <p className="mt-2 font-semibold">{t("menu.p_1")}: {formats.formatPrice(restaurant.cover_charge)}</p> : null}

                    {restaurant.has_filter &&
                        <div className="py-3 text-center">
                            <p className="text-base">Hai intolleranze o non ti piace qualcosa ?</p>
                            <FilterModalTrigger />
                        </div>
                    }

                </header>

                <NewTabs hasOrders={false} />

                {restaurant.has_newsletter == true ? showSubscriptionModal ? <SubscriptionModalTrigger showModal={showModal} setShowModal={setShowModal} /> : null : null}

            </div>
        </React.Fragment>
    );
}

export default Menu;
