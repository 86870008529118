import React from 'react';
import { useTranslation } from 'react-i18next';

function PoweredBy() {
    const { t } = useTranslation();

    return (
        <div className="powered-by w-full m-0 py-16 px-4">
            {/* <p className="pb-4 text-center text-xl">Supplied by <a href="https://www.thefork.it/" target="_blank" rel="noopener" className="font-bold underline">The Fork</a></p> */}

            <p className="text-center">
                {/* {t("poweredby.p_1.fragment_1")} */}
                Powered by
                <a href="https://mycia.it" target="_blank" rel="noopener" className="cursor-pointer ml-2 font-bold underline">MyCIA</a>
                {/* <a href="https://mycia.it" target="_blank" rel="noopener" className="cursor-pointer ml-2 font-bold underline">{t("poweredby.p_1.a_1")}</a> */}
            </p>
        </div>
    );
}

export default PoweredBy;
