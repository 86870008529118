import axios from 'axios';
import formats from '../utils/formats';

export const DOMAIN = "https://api-ristoratori-v3.mycia.it";

// 0:low --- 1:medium ---2:high
const dishesSustainabilityMap = {
    452409: 0, 452410: 1, 452411: 2, 452412: 1, 452413: 0, 452414: 0, 452415: 1, 452416: 1,
    452537: 0, 452538: 1, 452539: 2, 452540: 0, 452541: 0, 452542: 0, 452543: 1, 452544: 1,
}

async function doGetEnvironmentalImpact(dishId) {
    const PATH = "/foods/dishes/:dish_id/impact"

    var response = await axios.get(formats.formatUrl(DOMAIN + PATH, { "dish_id": dishId }));

    return response && response.data;
}

export default { doGetEnvironmentalImpact, dishesSustainabilityMap }
