import React, { useState, useEffect } from 'react';
import client from '../../logic/subscriptionModal';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import { useParams } from 'react-router';
import features from '../../utils/features';
import FilterModalTrigger from './FilterModalTrigger';
import './SubscriptionModalContentStyle.css';

function SubscriptionModalContent(props) {
    const { closerHandler } = props;

    const { id } = useParams();

    const [isEnable, setIsEnable] = useState(false);
    const [content, setContent] = useState({ name: "", surname: "", email: "", phone: "" });
    const [agree, setAgree] = useState({ first: false, second: false });

    const { t } = useTranslation();

    let classNames = "py-2 px-4 border border-blue-400 w-full rounded-full";

    function changeHandler(e) {
        const aux = e.target.value;
        console.log(aux)
        if (e.target.name == "name") {
            setContent(prev => ({ ...prev, name: aux }));
        }
        if (e.target.name == "surname") {
            setContent(prev => ({ ...prev, surname: aux }));
        }
        if (e.target.name == "email") {
            setContent(prev => ({ ...prev, email: aux }));
        }
        if (e.target.name == "phone") {
            setContent(prev => ({ ...prev, phone: aux }));
        }
    };

    function onAgree(e) {
        if (e.target.name == "agree") {
            const flag = e.target.checked;
            setAgree(prev => ({ ...prev, first: flag }));
        }
        if (e.target.name == "second-agree") {
            const flag = e.target.checked;
            setAgree(prev => ({ ...prev, second: flag }));
        }
    }

    useEffect(() => {
        if (agree.first == true && agree.second == true && content.name != "" && content.surname != "" && content.email != "") {
            setIsEnable(true);
        } else {
            setIsEnable(false);
        }
    }, [agree, content]);

    async function sendHandler() {
        const payload = {
            id: id,
            name: content.name,
            surname: content.surname,
            email: content.email,
            phone: content.phone,
        }

        client.doPost(payload);
        let storage = window.localStorage.getItem("has_subscription");
        let obj = [{ id: id }];
        if (storage) {
            obj = JSON.parse(storage)
            console.log(obj);
            obj.push({ id: id })
        }

        window.localStorage.setItem("has_subscription", JSON.stringify(obj));
        closerHandler();
    };

    return (
        <div className="subscription-modal-content overflow-auto text-black">
            <div className="relative mb-4 px-4">
                <button type="button" onClick={closerHandler} className="z-10 absolute text-2xl top-0 right-0 flex items-center justify-center mt-4 p-3 pb-0">
                    <IonIcon slot="icon-only" icon={close}></IonIcon>
                </button>
            </div>

            <div className="pb-4 p-1 px-8 mb-3">
                <p className="px-3 border-b border-b-gray-600">Vuoi ricevere offerte, scconti o promozioni dal locale e da MyCIA? 😍 </p>
                <div className="pt-3 text-sm">
                    <input type="text" name="name" id="name" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Scrivi il tuo nome" onChange={changeHandler} />
                    <input type="text" name="surname" id="surname" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Scrivi il tuo cognome" onChange={changeHandler} />
                    <input type="text" name="email" id="email" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Scrivi la tua Email" onChange={changeHandler} />
                    <input type="tel" name="phone" id="phone" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Telefono (FACOLTATIVO)" onChange={changeHandler} />
                </div>
                <div className="pt-6">
                    <div className="text-xs">
                        <input type="checkbox" name="agree" id="agree" className="mx-3" onClick={onAgree} />
                        <label>Accetto i <a href="https://..." className="underline">termini e le condizioni del servizio</a> e acconsento a MyCIA di inviarmi offerte, sconti o promozioni</label>
                    </div>
                    <div className="text-xs">
                        <input type="checkbox" name="second-agree" id="second-agree" className="mx-3" onClick={onAgree} />
                        <label>Accetto i secondi <a href="https://..." className="underline cursor-pointer">termini e le condizioni del servizio</a> e acconsento al locale di inviarmi offerte, sconti o promozioni</label>
                    </div>
                    <div className="my-2">
                        <button onClick={sendHandler} disabled={!isEnable} className={isEnable ? `${classNames}` : `${classNames} opacity-25 cursor-not-allowed`}>Invia</button>
                    </div>

                </div>

                {/* <div className="px-4 my-6 text-center">
                    <p className="text-sm">{t("deliverypopupmodalcontent.p_1.fragment_1")} <span className="text-mycia-green">{t("deliverypopupmodalcontent.p_1.span_1")} </span>{t("deliverypopupmodalcontent.p_1.fragment_2")}</p>
                    {features.toggler().includeFiltersFor() ?
                        <React.Fragment>
                            <p className="px-0">Hai qualche intolleranza o non ti piace qualcosa ?</p>
                            <div className="pb-4"><FilterModalTrigger /></div>
                        </React.Fragment> : null
                    }
                </div> */}
            </div>

            <div className="text-center">
                <button className="underline" onClick={closerHandler}>Chiudi</button>
            </div>
        </div>
    );
}

export default SubscriptionModalContent;
