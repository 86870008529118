import React from 'react';
import Drink from './Drink';
import Product from './Product';

function Items(props) {
    const { items } = props;

    return (
        <div className="items">
            {items.map((item, i) => {
                return item.is_drink ?
                    <Drink drink={item} key={i} /> :
                    <Product product={item} key={i} />;
            })}
        </div>
    );
}

export default Items;
