var axios = require('axios');
var qs = require('qs');

async function createCheckout(payload) {
    var config = {
        method: 'post',
        url: 'https://api-staging-ristoratori-v3.mycia.it/stripe/checkout',
        headers: {
            'Content-Type': "application/json"
        },
        data: JSON.stringify({
            "amount": payload.amount,
            "currency": "eur",
            "description": payload.description,
            "type": payload.type,
        })
    }

    try {
        const res = await axios(config);
        return res.data;
    } catch (e) {
        console.log(e);
    }
}

async function doPostTransaction(payload) {

    var data = qs.stringify({
        'type': payload.type,
        'ris_id': payload.risId,
        'order_id': payload.orderId,
        'payment_intent_id': payload.stripePaymentId,
    });

    var config = {
        method: 'post',
        url: 'https://api-staging-ristoratori-v3.mycia.it/stripe/transactions/',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
    };

    try {
        const res = await axios(config)
        return res.data;
    } catch (e) {
        console.log(e);
    }

}

export default {
    createCheckout,
    doPostTransaction,
};
