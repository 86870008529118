import axios from 'axios';
import formats from '../utils/formats';

//const ENDPOINT = "https://api-ristoratori.mycia.it/v1/restaurants/lang/:lang/id/:id";
//export const ENDPOINT = "https://api-ristoratori.mycia.it/restaurants/v1/?ris_id=:id";
export const ENDPOINT = "https://api-ristoratori.mycia.it/v2/restaurants/?id=:id&lang=:lang&consumetime=:consumetime";
export const TOKENENDPOINT = "https://api-ristoratori.mycia.it/v2/restaurants/?token=:token&lang=:lang";
export const SEARCHENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/?ris_id=:id&lang=:lang&search=:searchstring&consumetime=:consumetime";
export const FILTERS_ENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/all?ris_id=:id&allergieprincipali=:allergies&nongraditi=:foods&diete=:diets&consumetime=:consumetime";
export const BOOKINGENDPOINT = "https://orders.mycontactlessmenu.mycia.it/api/events/booking";

async function doGet(lang, id) {
    var consumetime = new Date().getHours() <= 15 ? 0 : 1;

    const isId = /^\d{1,5}$/.test(id);

    var response;

    if (isId) {
        response = await axios.get(formats.formatUrl(ENDPOINT, { "lang": lang, "id": id, "consumetime": consumetime }));
    } else {
        response = await axios.get(formats.formatUrl(TOKENENDPOINT, { "lang": lang, "token": id }));
    }

    return response && response.data;
}

async function doGetSearch(lang, id, searchString) {
    var consumetime = new Date().getHours() <= 15 ? 0 : 1;

    var response = await axios.get(formats.formatUrl(SEARCHENDPOINT, { "lang": lang, "id": id, "searchstring": searchString, "consumetime": consumetime }));

    return response && response.data;
}

async function doGetFilteredDishes(lang, id, allergies, foods, diets) {
    var consumetime = new Date().getHours() <= 15 ? 0 : 1;
    // Return from all courses in a single array
    (allergies != null) ? allergies = allergies : allergies = "";
    (foods != null) ? foods = foods : foods = "";
    (diets != null) ? diets = diets : diets = "";

    var response = await axios.get(formats.formatUrl(FILTERS_ENDPOINT, { "lang": lang, "id": id, "allergies": allergies, "foods": foods, "diets": diets, "consumetime": consumetime }));

    return response && response.data;
}

function doGetManualFilter(products, allFilteredDishes) {
    // Do manual filter (es. return items that Metide hide) between searched dishes and filtered dishes),
    // add manual user_suggestion and icon
    // and sorting array to put "non adatto alle tue esigenze" after other items

    let aux = products;
    let trovato = false;
    let sort_index = 0;
    for (let i = 0; i < products.length; i++) {
        for (let j = 0; j < allFilteredDishes.dishes.length && trovato == false; j++) {
            if (products[i].id == allFilteredDishes.dishes[j].id) {
                trovato = true;
                aux[i] = allFilteredDishes.dishes[j];
                aux[i].sort_index = 0;
            }
        }
        if (trovato == false) {
            aux[i].user_suggestion = "Il prodotto non è adatto alle tue esigenze";
            aux[i].other_user_suggestion = "Il prodotto non è adatto alle tue esigenze";
            aux[i].icon = "images/icons/menu/ic_menu_no_icon.png";
            aux[i].sort_index = sort_index;
            sort_index++;
        }
        trovato = false;
    }
    aux.sort((a, b) => {
        let keyA = a.sort_index;
        let keyB = b.sort_index;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
    });

    return aux;
}

function doPostBooking(payload) {
    var data = {
        "customer": payload.customer,
        "note": payload.note,
        "phone": payload.phone,
        "reserved_at": payload.reserved_at,
        "reserved_time": payload.reserved_time,
        "seats": payload.seats,
        "token": payload.token,
    };

    if (payload.order) {
        data["order"] = payload.order;
    }

    var config = {
        method: 'post',
        url: BOOKINGENDPOINT,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export default {
    doGet,
    doGetSearch,
    doGetFilteredDishes,
    doGetManualFilter,
    doPostBooking,
}
