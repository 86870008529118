import React from 'react';
import { useTranslation } from 'react-i18next';

function SearchDrink(props) {
    const { className, drinks, setResult, setIsSearching } = props;

    const { t } = useTranslation();

    function search(e) {
        if (e.target.value != "" && e.target.value != null) {
            const regexp = new RegExp(e.target.value, 'i');
            let result = drinks.map(drink => (
                ({ drinks: drink.drinks.filter(drink => regexp.test(drink.name)), course: { ...drink } }))
            );
            setResult(result);
            setIsSearching(true);
        } else {
            setResult(drinks);
            setIsSearching(false);
        }
    };

    return (
        <div>
            <input
                className={className}
                placeholder={t("searchdrink.input")}
                onChange={search} />
        </div>
    );
}

export default SearchDrink;
