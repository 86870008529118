import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonModal } from '@ionic/react';
import CtaItem from './CtaItem';
import '../cart/cta.css';

function CtaNoOrders(props) {
    const { element } = props;

    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const onOpenHandler = () => setShowModal(true);
    const onCloseHandler = () => setShowModal(false);

    return (
        <div className="cta-no-orders py-2 border-t border-t-grey-400 text-center bg-grey-400 rounded-b-lg">
            <button type="button" className="text-black w-full" onClick={onOpenHandler}>Vedi variazioni e/o aggiunte</button>

            <IonModal isOpen={showModal} onDidDismiss={onCloseHandler} mode="md" css-class="custom-cta">
                <CtaItem element={element} closerHandler={onCloseHandler} />
            </IonModal>
        </div>
    );
}

export default CtaNoOrders;
