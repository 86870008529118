import React from 'react';
import Drink from './Drink';
import formats from '../../utils/formats';
import clsx from 'clsx';

function Drinks(props) {
    let { drinks, index } = props;

    let classnames = [
        "drinks",
        { "even bg-white": formats.isEven(index) },
        { "odd bg-grey-200": !formats.isEven(index) }
    ];

    return (
        <div className={clsx(classnames)}>
            {0 === drinks.length ?
                (<p>No drinks.</p>) :
                (drinks.map((drink, i) => <Drink drink={drink} key={i} />))}
        </div>
    );
}

export default Drinks;
