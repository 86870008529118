import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeWidgetContent from "./StripeWidgetContent";

function StripeWidget(props) {
    const { order } = props;

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    return (
        <div className="stripe-widget">
            <Elements stripe={stripePromise}>
                <StripeWidgetContent order={order} />
            </Elements>
        </div>
    );
}

export default StripeWidget;
